import React from 'react';
import { Wrapper, Container } from '../styles/contact';

import MapsList from './map/MapsList';
import Form from './form/Form';
import * as FormHeader from './form/FormHeader';
import { themeColor } from '../styles/theme';

const Contact = ({ data }) => (
  <Wrapper data-target="contact">
    <Container>
      <MapsList data={data} />
    </Container>
    <Container css={{
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 15px',
      backgroundColor: themeColor('primary'),
    }}
    >
      <div css={{ maxWidth: '550px' }}>
        <FormHeader.Title>Drop us a line</FormHeader.Title>
        <FormHeader.Text>
          We would love to meet to chat about your idea. We’ll map out a scope
          and
          schedule that aligns with your product requirements, and offer
          recommendations for the most appropriate technology. Our goal is to
          help
          you launch quickly with rock-solid code.
        </FormHeader.Text>
        <Form />
      </div>
    </Container>
  </Wrapper>
);

export default Contact;
