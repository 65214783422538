import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import ArticleHeader from '../components/articles/ArticleHeader';
import ArticlesNavContainer from '../components/articles/ArticlesNavContainer';
import ArticlesNav from '../components/articles/ArticlesNav';
import { Article } from '../styles/article';
import Contact from '../components/Contact';

const Hero = styled.div`
  min-height: 350px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  
  @media (min-width: 768px) {
    min-height: 650px;
  }
  
  @media (min-width: 1920px) {
    min-height: 850px;
  }
`;

const CaseStudy = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { next, previous, type } = pageContext;
  const {
    name,
    excerpt,
    seo_title: seoTitle,
    seo_description: seoDescription,
    featured_image: featuredImage,
    preview_image: previewImage,
  } = frontmatter;
  const featured = featuredImage || previewImage;
  const showLabel = ['blog', 'case-studies'].includes(type);

  return (
    <Layout>
      <SEO title={seoTitle || name} description={seoDescription || excerpt} isPost />
      <ArticleHeader {...frontmatter} showLabel={showLabel} />
      <Hero image={featured && featured.publicURL} />
      {/* eslint-disable-next-line react/no-danger */}
      <Article dangerouslySetInnerHTML={{ __html: html }} />
      <Contact data={data.offices} />
      <ArticlesNavContainer>
        <ArticlesNav
          title={next.slug ? 'Next Project:' : 'Previous Project:'}
          link={next.slug || previous.slug}
          name={next.title || previous.title}
        />
      </ArticlesNavContainer>
    </Layout>
  );
};

export default CaseStudy;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        title
        label
        excerpt
        seo_title
        seo_description
        logo {
          publicURL
        }
        preview_image {
          publicURL
        }
        featured_image {
          publicURL
        }
      }
      html
    }
    offices: allMarkdownRemark(
      limit: 2,
      filter: {
        fields: {
          slug: {regex: "//addresses//"}
        }
        frontmatter: {
          visibility: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        ...MarkdownMapNode
      }
    }
  }
`;
