import React from 'react';
import { Link, Title } from '../../styles/articles-nav';

const ArticlesNav = ({ title, link, name }) => (
  <React.Fragment>
    <Title>{ title }</Title>
    <Link to={link}>{ name }</Link>
  </React.Fragment>
);

export default ArticlesNav;
