import React from 'react';
import { Wrapper, Container, Inner } from '../../styles/articles-nav';

const ArticlesNavContainer = ({ children }) => (
  <Wrapper>
    <Container>
      <Inner>
        {children}
      </Inner>
    </Container>
  </Wrapper>
);

export default ArticlesNavContainer;
