import React from 'react';
import styled, { css } from 'styled-components';
import hex2rgba from 'hex2rgba';
import { color } from '../../styles/theme';
import { Divider } from '../../styles/elements';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 15px;
  height: 50%;
  min-height: 285px;
  
  ${({ image }) => image && css`
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
  `}
  
  ${({ overlay }) => overlay && css`
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${hex2rgba(color(overlay), 0.75)};
      mix-blend-mode: multiply;
    }
  `}
`;

const Inner = styled.div`
  z-index: 1;
`;

const Title = styled.h2`
  margin-bottom: 0;
  font-size: 42px;
  color: ${color('dark')};
`;

const Content = styled.div`
  line-height: 1.4;
  color: ${color('dark')};
`;

const Map = ({ data, overlay }) => {
  const { frontmatter: { map, title } } = data;
  return (
    <Wrapper
      image={map && map.publicURL}
      overlay={overlay}
    >
      <Inner>
        <Title>{title}</Title>
        <Divider margin="20px 0" bgColor={color('lemon')} />
        {/* eslint-disable-next-line react/no-danger */}
        <Content dangerouslySetInnerHTML={{ __html: data.html }} />
      </Inner>
    </Wrapper>
  );
};

export default Map;
