import React from 'react';
import { graphql } from 'gatsby';

import Map from './Map';

const MapList = ({ data }) => (
  <>
    {data.edges.map(({ node }, i) => (
      <Map
        key={node.id}
        data={node}
        overlay={i ? 'gray200' : 'turquoise-blue'}
      />
    ))}
  </>
);

export default MapList;

export const query = graphql`
  fragment MarkdownMapNode on MarkdownRemarkEdge {
    node {
      id
      frontmatter {
        title
        map {
          publicURL
        }
      }
      html
    }
  }
`;
